import React from 'react';
import PropTypes from 'prop-types';

import Img from '../../Image';
import PhaseNav from '../PhaseNav';

import styles from './Header.module.scss';

const Header = ({ heading, phases, image, imageSharp }) => (
  <section className={styles.header}>
    <div className={styles.image}>
      <Img image={image} sharpImage={imageSharp} />
    </div>
    <div className={styles.content}>
      <span className={styles.overline}>Technology</span>
      <h1 className={styles.title}>{heading}</h1>

      {phases && phases.length > 0 && (
        <div className={styles.phaseNav}>
          <PhaseNav nav={phases} />
        </div>
      )}
    </div>
  </section>
);

Header.defaultProps = {
  heading: '',
  phases: [],
};

Header.propTypes = {
  heading: PropTypes.string,
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      slug: PropTypes.string,
      status: PropTypes.string,
      rendering: PropTypes.object,
      rendering_sharp_small: PropTypes.object,
    }),
  ),
  image: PropTypes.object,
  imageSharp: PropTypes.object,
};

export default Header;
