import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { slugify } from '../utils';

import SEO from '../components/SEO';
import Header from '../components/TechPage/Header';
import Phase from '../components/TechPage/Phase';
import Timeline from '../components/TechPage/Timeline';
import ScrollPixel from '../components/ScrollPixel';
import NotFoundPage from './404';
import Footer from '../components/Footer';

const TechPage = ({ data, location }) => {
  const pageData = data.prismic.allTech_pages.edges.slice(0, 1).pop();

  if (!pageData || !pageData.node) {
    return <NotFoundPage />;
  }

  const {
    heading,
    phases: phasesRaw,
    topper,
    topper_image,
    topper_imageSharp,
    timeline_title,
    cta_label,
    cta_link,
    seo,
  } = pageData.node;

  // Map out and make consistent the phase data. Adding the filter removes any empty phases.
  const phases = phasesRaw
    .filter(({ phase }) => phase)
    .map(({ phase }) => ({
      headline: RichText.asText(phase.headline),
      slug: slugify(RichText.asText(phase.headline)),
      body: phase.body,
      status: phase.phase_status,
      milestones: phase.milestones,
      rendering: phase.photo_rendering,
      rendering_sharp: phase.photo_renderingSharp,
      rendering_sharp_small: phase.photo_renderingSharp_small,
      photos: phase.photos,
      related_link: phase.related_link,
      related_link_custom_text: phase.related_link_custom_text,
    }));

  return (
    <>
      <SEO
        title="Technology"
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />
      <Header
        heading={heading && RichText.asText(heading)}
        phases={phases}
        image={topper_image}
        imageSharp={topper_imageSharp}
      />

      {phases && phases.length > 0 && (
        <div id="techpage-phases" style={{ position: 'relative' }}>
          <ScrollPixel id="techpage-nav-in" style={{ top: 0 }} />
          {phases.map(
            (phase, index) =>
              phase && (
                <Phase
                  key={phase.slug}
                  phase={phase}
                  tag={index === 0 ? 'Now' : index === 1 ? 'Next' : undefined}
                  cta={
                    index === 0 &&
                    cta_link &&
                    cta_label && {
                      label: cta_label,
                      link: cta_link,
                    }
                  }
                />
              ),
          )}
          <ScrollPixel id="techpage-nav-out" style={{ bottom: '50vh' }} />
        </div>
      )}

      <Timeline title={timeline_title} topper={topper} />

      <Footer />
    </>
  );
};

TechPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allTech_pages: PropTypes.object,
    }),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
};

export const featurePhotoFragment = graphql`
  fragment FeaturePhotoQuery on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const thumbnailFragment = graphql`
  fragment ThumbnailQuery on File {
    childImageSharp {
      fluid(maxWidth: 650) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const renderingFragment = graphql`
  fragment RenderingImageQuery on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query TechPageQuery {
    prismic {
      allTech_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            heading
            topper_image
            topper_imageSharp {
              ...RenderingImageQuery
            }
            topper {
              image
              imageSharp {
                ...RenderingImageQuery
              }
              label
              title
            }
            cta_label
            cta_link {
              ...LinkQuery
            }
            timeline_title
            phases {
              phase {
                ... on PRISMIC_Phase {
                  headline
                  body
                  phase_status
                  milestones {
                    name
                    status
                  }

                  photo_rendering
                  photo_renderingSharp {
                    ...RenderingImageQuery
                  }

                  photo_renderingSharp_small: photo_renderingSharp {
                    childImageSharp {
                      fluid(maxHeight: 300) {
                        src
                      }
                    }
                  }

                  photos {
                    feature_photo
                    feature_photoSharp {
                      ...FeaturePhotoQuery
                    }
                    feature_video {
                      _linkType
                      ... on PRISMIC__FileLink {
                        _linkType
                        url
                        name
                      }
                    }
                    thumbnail_one
                    thumbnail_oneSharp {
                      ...ThumbnailQuery
                    }
                    thumbnail_two
                    thumbnail_twoSharp {
                      ...ThumbnailQuery
                    }
                  }

                  related_link {
                    ...LinkQuery
                  }

                  related_link_custom_text
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TechPage;
