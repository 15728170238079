import React from 'react';
import PropTypes from 'prop-types';

import StatusDot from '../../StatusDot';

import styles from './Milestones.module.scss';

const Milestones = ({ milestones }) => (
  <ul>
    <li className={styles.header}>
      <div className={styles.header__title}>
        <h3>Milestones</h3>
      </div>
      <div className={styles.header__status} aria-hidden>
        Done
      </div>
      <div className={styles.header__status} aria-hidden>
        Doing
      </div>
      <div className={styles.header__status} aria-hidden>
        To Do
      </div>
    </li>

    {milestones &&
      milestones.length > 0 &&
      milestones.map(({ name, status }) => (
        <li className={styles.milestone} key={name}>
          <p className={styles.milestone__title}>
            {name}
            <span className={styles.hide}>, {status}</span>
          </p>
          <div className={styles.milestone__status} aria-hidden>
            <StatusDot active={status === 'Done'} />
          </div>
          <div className={styles.milestone__status} aria-hidden>
            <StatusDot active={status === 'Doing'} />
          </div>
          <div className={styles.milestone__status} aria-hidden>
            <StatusDot active={status === 'To-do'} />
          </div>
        </li>
      ))}
  </ul>
);

Milestones.defaultProps = {
  milestones: [],
};

Milestones.propTypes = {
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
};

export default Milestones;
