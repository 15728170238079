import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScrollPixel.module.scss';

const ScrollPixel = ({ id, style }) => <span id={id} style={style} className={styles.pixel} />;

ScrollPixel.defaultProps = {
  style: {},
};

ScrollPixel.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default ScrollPixel;
