import React from 'react';
import PropTypes from 'prop-types';

import Img from '../../Image';

import styles from './Timeline.module.scss';

const Timeline = ({ topper, title }) => (
  <section className={styles.timeline}>
    <div className={styles.content}>
      <h2 className={styles.title}>{title}</h2>
      {topper && topper.length > 0 && (
        <figure className={styles.phases}>
          {topper.map(({ image, imageSharp }, index) => (
            <div key={index} className={styles.phase}>
              {image && <Img image={image} sharpImage={imageSharp} />}
            </div>
          ))}
        </figure>
      )}
      {topper && topper.length > 0 && (
        <div className={styles.text}>
          {topper.map(({ label, title }, index) => (
            <div key={index} className={styles.text__item}>
              <p className={styles.text__number}>{label}</p>
              <p className={styles.text__label}>{title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  </section>
);

Timeline.defaultProps = {
  topper: [],
};

Timeline.propTypes = {
  title: PropTypes.string,
  topper: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      imageSharp: PropTypes.object,
      label: PropTypes.string,
    }),
  ),
};

export default Timeline;
