import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { className } from '../../../utils';
import Scrollchor from 'react-scrollchor';

import styles from './PhaseNav.module.scss';

const PhaseNav = ({ nav }) => {
  const [navVisible, setNavVisibility] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const [activePhase, setActivePhase] = useState(null);

  useEffect(() => {
    if (typeof IntersectionObserver !== 'function') {
      return;
    }

    const navIn = document.getElementById('techpage-nav-in');
    const navOut = document.getElementById('techpage-nav-out');
    const phases = document.querySelectorAll('#techpage-phases > section');

    const observer = new IntersectionObserver(entries => {
      // Ignore the observation on page load.
      if (entries.length > 1) {
        return;
      }

      const e = entries.slice(0, 1).pop();

      if (e.target.id === 'techpage-nav-in') {
        // If the entry is the "in" pixel, show the nav when the pixel is above the viewport.
        setNavVisibility(e.boundingClientRect.y < 0);
      } else {
        // Reversed logic for the lower pixel - when the pixel is above the viewport, hide the nav.
        setNavVisibility(e.boundingClientRect.y >= 0);
      }
    });

    let scrollPos = 0;

    const phaseObserver = new IntersectionObserver(
      entries => {
        // Ignore the observation on page load.
        if (entries.length > 1) {
          return;
        }

        const e = entries.slice(0, 1).pop();
        const newScroll = window.scrollY;
        const scrollingDown = newScroll > scrollPos;
        scrollPos = newScroll;

        if (scrollingDown && e.isIntersecting) {
          setActivePhase(e.target.id);
        } else if (!scrollingDown && !e.isIntersecting) {
          const previousPhase = e.target.previousSibling;
          if (previousPhase) {
            setActivePhase(previousPhase.id);
          }
        }
      },
      { rootMargin: `-320px` },
    );

    if (navIn) {
      observer.observe(navIn);
    }

    if (navOut) {
      observer.observe(navOut);
    }

    if (phases.length > 0) {
      phases.forEach(ph => phaseObserver.observe(ph));
    }

    return () => {
      observer.disconnect();
      phaseObserver.disconnect();
    };
  }, []);

  return (
    <Fragment>
      <nav className={styles.headerNav}>
        <ul className={styles.headerNav__phases}>
          {nav.map(({ headline, slug, status }) => (
            <li className={styles.headerNav__phase} key={slug}>
              <Scrollchor to={`#${slug}`} className={styles.headerNav__phaseLink}>
                <div className={styles.headerNav__phaseTitle}>{headline}</div>
                <div className={styles.headerNav__phaseStatus}>{status}</div>
                <div className={styles.headerNav__phaseArrow}>↓</div>
              </Scrollchor>
            </li>
          ))}
        </ul>
      </nav>

      <nav
        {...className(
          styles.stickyNav,
          navVisible && styles.stickyNavVisible,
          navExpanded && styles.stickyNavExpanded,
        )}
        onMouseOver={() => setNavExpanded(true)}
        onFocus={() => setNavExpanded(true)}
        onMouseOut={() => setNavExpanded(false)}
        onBlur={() => setNavExpanded(false)}>
        <ul className={styles.stickyNav__phases}>
          {nav.map(({ headline, slug, rendering, rendering_sharp_small }) => (
            <li
              {...className(
                styles.stickyNav__phase,
                slug === activePhase && styles.stickyNav__phaseActive,
              )}
              key={slug}>
              <Scrollchor
                to={`#${slug}`}
                className={styles.stickyNav__phaseLink}
                beforeAnimate={() => setNavExpanded(false)}
                tabIndex={'-1'}>
                <span className={styles.stickyNav__phaseTitle}>{headline}</span>
                <figure className={styles.stickyNav__phaseImage}>
                  <img
                    src={
                      rendering_sharp_small
                        ? rendering_sharp_small.childImageSharp.fluid.src
                        : rendering.url
                    }
                    alt={rendering.alt || `Picture of ${headline}`}
                  />
                </figure>
              </Scrollchor>
            </li>
          ))}
        </ul>
      </nav>
    </Fragment>
  );
};

PhaseNav.defaultProps = {
  nav: [],
};

PhaseNav.propTypes = {
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      slug: PropTypes.string,
      status: PropTypes.string,
      rendering: PropTypes.object,
      rendering_sharp_small: PropTypes.obejct,
    }),
  ).isRequired,
};

export default PhaseNav;
