import React from 'react';
import * as PropTypes from 'prop-types';
import { className } from '../../utils';

import styles from './ColumnText.module.scss';

const ColumnText = ({ pushColumns, children }) => (
  <div {...className(styles.columnText, pushColumns && styles[`columnTextPush${pushColumns}`])}>
    <div className={styles.columns}>{children}</div>
  </div>
);

ColumnText.defaultProps = {
  pushColumns: 0,
  children: null,
  imagesAfter: false,
  imagesBefore: false,
};

ColumnText.propTypes = {
  pushColumns: PropTypes.number,
  children: PropTypes.node,
};

export default ColumnText;
