import React from 'react';
import * as PropTypes from 'prop-types';
import { className } from '../../utils';

import styles from './StatusDot.module.scss';

const StatusDot = ({ active }) => (
  <div {...className(styles.statusDot, active && styles.statusDotActive)} />
);

StatusDot.defaultProps = {
  active: false,
};

StatusDot.propTypes = {
  active: PropTypes.bool,
};

export default StatusDot;
