import React from 'react';
import * as PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { linkResolver, contentSerializer } from '../../../utils';

import Button from '../../Button';
import ColumnText from '../../ColumnText';
import ImageGrid from '../../ImageGrid';
import Milestones from '../Milestones';

import styles from './Phase.module.scss';

const Phase = ({ phase, tag, cta }) => {
  const {
    headline,
    slug,
    body,
    milestones,
    rendering_sharp,
    photos,
    related_link,
    related_link_custom_text,
    status,
  } = phase;
  const gridPhotos = photos ? photos.slice(0, 1).pop() : [];

  return (
    <section className={styles.phase} id={slug}>
      {cta ? (
        <div className={styles.ctaContainer}>
          <div className={styles.ctaContent}>
            <div className={styles.cta}>
              <Button
                url={linkResolver(cta.link)}
                text={cta.label}
                external={cta.link._linkType === 'Link.web'}
                target={cta.link.target}
                circleFull
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.content}>
        {rendering_sharp && (
          <div
            className={styles.rendering}
            style={{ backgroundImage: `url(${rendering_sharp.childImageSharp.fluid.src})` }}></div>
        )}

        <div className={styles.header}>
          <div className={styles.header__text}>
            <div className={styles.header__content}>
              <div className={styles.header__top}>
                <p className={styles.header__label}>{tag || status}</p>
                {related_link && (
                  <div className={styles.header__relatedLink}>
                    <Button
                      url={linkResolver(related_link)}
                      text={related_link_custom_text ? related_link_custom_text : 'Learn More'}
                    />
                  </div>
                )}
              </div>
              {headline && <h2 className={styles.header__title}>{headline}</h2>}
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.body__text}>
            <ColumnText pushColumns={3}>
              <RichText render={body} htmlSerializer={contentSerializer} />
            </ColumnText>
          </div>
          <ImageGrid photos={gridPhotos} />
        </div>

        {milestones && milestones.length > 0 && (
          <div className={styles.footer}>
            <div className={styles.footer__container}>
              <div className={styles.footer__milestones}>
                <Milestones milestones={milestones} />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

Phase.defaultProps = {
  phase: {
    body: [],
    milestones: [],
    rendering: null,
    rendering_sharp: null,
    rendering_sharp_small: null,
    photos: [],
    related_link: null,
  },
};

Phase.propTypes = {
  tag: PropTypes.string,
  phase: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    body: PropTypes.array,
    milestones: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.string,
      }),
    ),
    rendering: PropTypes.object,
    rendering_sharp: PropTypes.object,
    rendering_sharp_small: PropTypes.object,
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        photo: PropTypes.object,
        photoSharp: PropTypes.object,
      }),
    ),
    related_link: PropTypes.object,
    status: PropTypes.string,
  }),
  cta: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.object,
  }),
};

export default Phase;
